/* cursor.css */
.blob {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  border-radius: 100%;
  background: radial-gradient(circle, #60aafe 40%, #00000000 100%);
  filter: blur(150px);
  transition: all 450ms ease-out;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  opacity: 0.8;
  transform: translate(-50%, -50%);
  z-index: 1;
  mix-blend-mode: screen;
}

.container43 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.content-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}
