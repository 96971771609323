body {
  overflow: hidden;
  z-index: 0;
  position: relative;
}

.container {
  height: 100vh;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.loader-animation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.fade-out {
  opacity: 0;
}
.container::-webkit-scrollbar {
  display: none;
}

.section {
  height: 100vh;
  width: 100vw;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  opacity: 1;
  transition: opacity 5s ease-in-out;
  z-index: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

#section1 {
  background-image: url("../assets/images/logobg.png");
  background-color: #000;
  object-fit: cover;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.bgBlck {
  background-color: #000;
}
.section1 {
  background-image: url("../assets/images/logobg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
#section7 {
  background-color: #000;
  object-fit: contain;
}
#section8 {
  background-image: url("../assets/images/logobg.png");
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.log_img {
  animation: rotate 5s linear infinite;
  background-repeat: no-repeat;
  background-size: contain;
}
.section2 {

  background-color: #000;
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
}
@keyframes rotate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}


#section2 {
  align-items: end;
  position: relative;
  background-color: #000 !important;
}

.section2 {
  width: 100%;
}

.container,
.MuiTypography-root {
  position: relative;
  z-index: 1;
}
.adventure_title {
  font-family: "Orbitron";
  font-size: 64px !important;
  letter-spacing: 2px;
  white-space: pre-line;
}

.arrow_icon {
  color: #fff;
  animation: upDown 1s ease-in-out infinite;
  cursor: pointer;
}

@keyframes upDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}


@media only screen and (max-width: 1024px) {
  .adventure_title {
    font-size: 48px !important; /* Adjust the font size as needed */
  }
}


@media only screen and (max-width: 600px) {
  .adventure_title {
    font-size: 28px !important; /* Adjust the font size as needed */
  }
  #section8 {
    height:100vh;
  }
}
