.particlesContainer {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }
  
  .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: moveParticles 10s linear infinite;
  }
  
  @keyframes moveParticles {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
  .particles:nth-child(2) {
    top: 100%;
  }
  
  .particlesImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  