#sectionAbout1,
#sectionAbout2,
#sectionAbout3 {
  background-image: url("../assets/images/logobg.png");
  object-fit: cover;
  display: flex;
  background-position: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.team_section {
  padding: 70px 100px; 
}

.indicator_border {
  height: 36px;
  padding: 1px;
  background-color: #fff;
  position: absolute;
  bottom: -5.5px;
  left: 48%;
  width: 4px;
  transform: translateY(50%);
}

.lastBorder {
  position: relative;
  top: 40px;
  right: 25px;
  height: 40px;
  width: 4px;
}

.lightningImg {
  background-color: #ff4f17;
  padding: 12px;
  border-radius: 50px;
  filter: drop-shadow(0 0 2px #FF4F17) drop-shadow(0 0 10px #FF4F17);
  border-radius: 50%;
}

#sectionAbout4 {
  background-image: url("../assets/images/logobg.png");
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
}


@media screen and (max-width: 767px) {
  .team_section {
    padding: 0px 25px 0px; 
  }
  #sectionAbout1,#sectionAbout2,#sectionAbout3{
    height: 110vh;
  }
  .indicator_border {
    height: 44px;
    bottom: -10px;
  }  
}
